@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);

@layer base {
  body {
    @apply font-[Anton];
    @apply text-white;
    @apply bg-gradient-to-tr from-[#131313] to-[#272521];
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  li {
    @apply px-4;
  }
  #name {
    @apply font-[Lobster];
  }
}

@layer components {
  .Ngradient {
    @apply bg-gradient-to-tl from-[#fb7e14] to-[#fbbd14];
  }
  .Hgradient {
    @apply bg-gradient-to-tl from-[#fbbd14] to-[#fb7e14];
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(7, 7, 7, 0.295),
    hsla(0, 0%, 47%, 0.39)
  ) !important;
}
